@import url("https://fonts.google.com/specimen/Josefin+Sans");
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    @apply dark:bg-containerBg-dark text-lg;
}

.filterButton
{
    @apply dark:hover:text-filterText-darkHover hover:text-filterText-lightHover hover:cursor-pointer;
}

.footer{

}